import React from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import { Parallax } from 'react-scroll-parallax'

export default function Hero({image, title}) {
    const theImage = getImage(image.localFile);
    return (
        <Styling>
            <h1 className="title minerva x-large">{title}</h1>
            <div className="parallax">            
                <Parallax className="custom-class" y={[-40, 20]} tagOuter="figure">
                    <GatsbyImage loading="eager" image={theImage} className="image" alt={'test'} />
                </Parallax>
            </div>
        </Styling>
    )
}
