import React from 'react'
import { graphql } from 'gatsby'
import Hero from '../components/global/Hero'
import parse from 'html-react-parser'
import IntroSectionReuseable from '../components/reuseable/IntroSectionReuseable'
import GoogleMapReact from 'google-map-react';
import {Styling} from '../styles/contact/styled'
import {FaMapMarkerAlt} from 'react-icons/fa'
import {HiPhone} from 'react-icons/hi'
import {IoIosMail} from 'react-icons/io'
import {FaFax} from 'react-icons/fa'
import MapIcon from '../images/map.inline.svg';
import Texture from '../components/animation/Texture'
import { Helmet } from 'react-helmet'
import Form from '../components/global/Form'

export default function Contact({data}) {
  

  return (
    <Styling>
        <Helmet>
          <title>{data.wpPage.seo.title}</title>
          <meta name="description" content={data.wpPage.seo.metaDesc} />
          <meta name="og:description" content={data.wpPage.seo.metaDesc} />
          <meta property="og:title" content={data.wpPage.seo.title} />          
          <meta property="og:image" content={'https://morlandbathrooms.com'+data.wpPage.customHeroImage.heroImage.localFile.publicURL} />
      </Helmet>
      <div className="offpage-translate offpage-translate-bg"></div>
      <div className="offpage-translate">
        <Hero image={data.wpPage.customHeroImage.heroImage} title={data.wpPage.title}/>
        <IntroSectionReuseable defaultPageFields={data.wpPage.defaultPageFields} />
        <div className="contact-info">
            <div className="top">
                <div className="left">
                    <div className="title libre title_l">Get in touch</div>
                    <div className="contact">
                        <a className='address' href={data.wp.siteSettings.acfOptions.googleMapDirectionsUrl} target="_blank" rel="noreferrer noopener"><div className="icon"><FaMapMarkerAlt /></div><div>{parse(data.wp.siteSettings.acfOptions.address)}</div></a>
                        <a className='email' href={`mailto:${data.wp.siteSettings.acfOptions.email}`}><span className="icon"><IoIosMail /></span>{data.wp.siteSettings.acfOptions.email}</a>
                        <a className='phone' href={`tel:${data.wp.siteSettings.acfOptions.phoneFormatted}`}><span className="icon"><HiPhone /></span>{data.wp.siteSettings.acfOptions.phone}</a>
                        <div className='fax'><span className="icon"><FaFax /></span>{data.wp.siteSettings.acfOptions.fax}</div>
                    </div>
                </div>
                <div className="form-wrap">
                    <Form />
                </div>
            </div>
            <div className="right">
            <div className="title libre title_l">Find us</div>
            <div className="map">
                <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyA1rOAp0Tx-RK3EqtzAko69tLXC2gR3Jdk' }}
                options={{
                    styles: [
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e9e9e9"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 29
                            },
                            {
                                "weight": 0.2
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 18
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            },
                            {
                                "lightness": 21
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dedede"
                            },
                            {
                                "lightness": 21
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "saturation": 36
                            },
                            {
                                "color": "#333333"
                            },
                            {
                                "lightness": 40
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            },
                            {
                                "lightness": 19
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#fefefe"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#fefefe"
                            },
                            {
                                "lightness": 17
                            },
                            {
                                "weight": 1.2
                            }
                        ]
                    }
                ]
                }}
                defaultCenter={{
                    lat: data.wp.siteSettings.acfOptions.map.latitude,
                    lng: data.wp.siteSettings.acfOptions.map.longitude
                }}
                
                defaultZoom={16}
                >
                
                    <MapIcon 
                    lat={data.wp.siteSettings.acfOptions.map.latitude}
                    lng={data.wp.siteSettings.acfOptions.map.longitude}
                    className="mapMarker"
                    />
                
                </GoogleMapReact>
            </div>
            </div>
        </div>
        <Texture 
            className='texture full' 
            opacity={0.05}  
            position='absolute'
            bottom="0"
            height={''}
            top="0"
            watchScroll={false}
            />
        </div>
    </Styling>
  )
}

export const query = graphql`
  query {
    wp {
      siteSettings {
        acfOptions {
          email
          fax
          googleMapDirectionsUrl
          phone
          phoneFormatted
          address
          map {
            longitude
            latitude
            zoom
          }
        }
      }
    }
    wpPage(title: {eq: "Contact"}) {
      id
      title
      seo {
        title
        metaDesc
      }
      defaultPageFields {
        contentTitle
        contentText
        contentImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 2000
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      customHeroImage {
          heroImage {
              localFile{
                publicURL
                childImageSharp {
                    gatsbyImageData(
                    width: 1500
                    placeholder: NONE
                    formats: [AUTO, WEBP]
                    )
                }
              }
          }
      }
    }
  }
`