import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`
width: 100%;
position: relative;
padding:4rem 2rem;
/* background:var(--blue_light); */
display: flex;
align-items: center;
z-index: 200;

    @media only screen and (min-width: 450px) {
    padding:4rem;
    }
    @media only screen and (min-width: 1024px) {
    padding:8rem 10rem;
    }

    .image-wrapper{
    width: 40%;
    margin-right: 8rem;
    position: relative;
    display:none;

        @media only screen and (min-width: 1100px) {
        display: block;
        }

        .image{
        //border:1rem solid var(--white);
        background:var(--white);
        }

    }

    .text-wrapper{
    width:100%;
    position: relative;

        @media only screen and (min-width: 1100px) {
        width:50%;
        }


        .title{
        margin-bottom: 4rem;
        }

        .text{
        font-size: clamp(1.6rem, 1.75vw, 2rem);
        line-height:1.75em;

            p{
            margin-bottom: 2rem;
            }
        }
        
    }

`

export { Styling }