import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`
z-index: 100;
position: relative;

    .contact-info{
    padding:0 2rem 8rem 2rem;
    width: 100%;
    position: relative;
    display:flex;
    z-index: 100;
    position: relative;
    flex-wrap: wrap;

        @media only screen and (min-width: 450px) {
            padding:0 4rem  4rem 4rem;
        }

        @media only screen and (min-width: 2000px) {
        flex-wrap: nowrap;
        }

        @media only screen and (min-width: 1024px) {
            padding:0rem 10rem 8rem 10rem;
        }

        .title{
        margin-bottom:4rem;
        }

        .left{
        width: 100%;
        position: relative;
        display: block;
        margin-bottom: 4rem;
        border-top:1px solid var(--blue_dark);
        padding-top:4rem;

            @media only screen and (min-width: 860px) {
            width:50%;
            }

            @media only screen and (min-width: 2000px) {
            margin-right: 8rem;
            margin-bottom: 0;
            
            border-top:0;
            padding-top:0;
            }

            .contact{
            width: 100%;
            position: relative;

                .address,
                .email,
                .phone{
                ${({theme}) => theme.easing('0.5')}  

                    &:hover{
                    color:var(--white);
                    }

                }
                
                .icon{
                margin-right: 1rem;
                top:4px;
                position: relative;
                }
                
                .address,
                .email,
                .phone,
                .fax{
                    
                    align-items: center;
                    color:var(--blue_dark);
                    text-decoration:none;
                    display: block;
                    margin-bottom:4rem;
                    position: relative;
                    font-size:clamp(1.6rem, 4vw, 2.5rem);
                    line-height: 1.25em;

                    div{
                        display:inline-flex;
                    }
                }
                .fax{
                    margin-bottom:0;
                }   
            }
            
            
        }
        
         .form-wrap{
         border-top:1px solid var(--blue_dark);
         width:100%;
         padding:4rem 0;

            @media only screen and (min-width: 860px) {
            width:50%;
            }

         }

        .top{
        width:100%;
        display:inline-flex;
        flex-wrap:wrap;
        }
        

        .right{
        width:100%;
        border-top:1px solid var(--blue_dark);
        padding-top:4rem;

            @media only screen and (min-width: 2000px) {
            width: calc(60% - 8rem);
            border-top:0;
            padding-top:0;
            }

            .map{
            width:100%;
            height:40vh;
            //border:1rem solid var(--white);
            background:var(--white);

                .mapMarker{
                transform: translate(-50%, -50%);
                }

            }

        }

    }
`

export { Styling }