import React, { useState, useRef } from "react";
import { Link, navigate } from "gatsby";
import { Styling } from "./styled.js";

export default function Form() {
  const [formData, setFormData] = useState();
  const [formName, setFormName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formPhone, setFormPhone] = useState("");
  const [formPreffered, setFormPreffered] = useState("");
  const [formMessage, setFormMessage] = useState("");
  const formRef = useRef();

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  const handleInput = (e) => {
    if (e.target.name === "name") {
      setFormName(e.target.value);
    }
    if (e.target.name === "email") {
      setFormEmail(e.target.value);
    }
    if (e.target.name === "phone") {
      setFormPhone(e.target.value);
    }
    if (e.target.name === "message") {
      setFormMessage(e.target.value);
    }
    if (e.target.name === "preferred") {
      setFormPreffered(e.target.value);
    }
    setFormData({
      name: formName,
      email: formEmail,
      message: formMessage,
      preferred: formPreffered,
      phone: formPhone,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = formRef.current;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formData,
      }),
    })
      .then(() => navigate("/"))
      .catch((error) => alert(error));

    setFormData();
  };

  return (
    <Styling>
      <form
        className="form"
        id="call-to-action"
        method="POST"
        data-netlify="true"
        name="call-to-action"
        ref={formRef}>
        <div className="input_wrap">
          <label htmlFor="name">Name</label>
          <input
            onChange={(e) => handleInput(e)}
            onBlur={(e) => handleInput(e)}
            type="text"
            name="name"
            required
          />
        </div>
        <div className="input_wrap">
          <label htmlFor="email">Email</label>
          <input
            onChange={(e) => handleInput(e)}
            onBlur={(e) => handleInput(e)}
            type="email"
            name="email"
            required
          />
        </div>
        <div className="input_wrap">
          <label htmlFor="phone">Phone</label>
          <input
            onChange={(e) => handleInput(e)}
            onBlur={(e) => handleInput(e)}
            type="text"
            name="phone"
          />
        </div>
        <div className="select_wrap">
          <label htmlFor="preferredContact">Preffered Contact</label>
          <select
            name="preferred"
            id="preferredContact"
            onChange={(e) => handleInput(e)}
            onBlur={(e) => handleInput(e)}>
            <option selected disabled>
              Please choose a preferred contact method
            </option>
            <option value="Email">Email</option>
            <option value="Phone">Phone</option>
          </select>
        </div>
        <div className="textarea_wrap">
          <label htmlFor="message">Message</label>
          <textarea
            onChange={(e) => handleInput(e)}
            onBlur={(e) => handleInput(e)}
            name="message"
          />
        </div>
        <button className="submit button" onClick={(e) => handleSubmit(e)} type="submit">
          Send
        </button>
      </form>
    </Styling>
  );
}
