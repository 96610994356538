import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`
width:100%;
height:100%;
/* min-height: 60vh; */
padding-bottom: clamp(300px, 50%, 600px);
position: relative;
z-index: 200;
position: relative;
z-index: 200;
position: relative;

    .image{
    width: 100%;
    height:120%;
    position: absolute;
    z-index: 0;
    top:-5vw;

        @media only screen and (min-width: 2000px) {
        top:0;
        }
    
        &:before{
        width: 100%;
        height:100%;
        position: absolute;
        z-index: 10;
        content:"";
        top:0;
        left:0;
        background:#9C9C9C;
        mix-blend-mode: multiply;
        }

        
    }

    .parallax,
    .custom-class{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .parallax-inner{
        width: 100%;
        height: 100%;
    }
    
    .title{
    position:absolute;
    bottom:4rem;
    z-index:20;
    font-size:clamp(5rem, 10vw, 10rem);
    color:var(--blue_light);
    left:2rem;

        @media only screen and (min-width: 450px) {
        left:4rem;
        }
        @media only screen and (min-width: 1024px) {
        left:10rem;
        }

    }

`

export { Styling }