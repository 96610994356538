import React from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import parse from 'html-react-parser'

export default function IntroSectionReuseable({defaultPageFields, productFields}) {
    const {contentTitle, contentText, contentImage} = defaultPageFields? defaultPageFields : ''
    const {mainPageText, mainPageTitle, mainPageImage} = productFields ? productFields : ''
    const theImage = getImage(contentImage?.localFile || mainPageImage?.localFile);
    
    return (
        <Styling>

            {contentImage !== null ? 
            
                <div className="image-wrapper">
                    <GatsbyImage image={theImage} className="image" alt={'test'} />
                </div>

                : ''
            }

            <div className="text-wrapper">
                <h2 className="libre large title">{contentTitle || mainPageTitle}</h2>
                <div className="text">{parse(contentText || mainPageText)}</div>
            </div>

        </Styling>
    )
}
