import styled from 'styled-components'
import dd from '../../../images/dd.svg'

const Styling = styled.div`
.form{
width:100%;
position: relative;


    form{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-wrap:wrap;
    }
    
    .textarea_wrap,
    .input_wrap,
    .select_wrap{
    display: inline-flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    width: 100%;

        /* @media only screen and (min-width: 550px) {                
            width: calc(50% - 1rem);
        }

        &:first-child{
            @media only screen and (min-width: 550px) {                
            margin-right:2rem;
            }
        } */

        label{
        width: 100%;
        margin-bottom: 0.5rem;
        position: relative;
        }

        select,
        textarea,
        input{
        width: 100%;
        position: relative;
        padding:1rem;
        border:1px solid var(--blue_light);
        background:var(--white);
        -webkit-appearance:none;
        border-radius:0;
        }

        select{
        -webkit-appearance:none;
        border-radius:0;
        background-color:var(--white);
        background-image:url(${dd});
        background-repeat:no-repeat;
        background-size:auto 30%;
        background-position:calc(100% - 1rem) 50%;
        }


    }

    .textarea_wrap{
        @media only screen and (min-width: 550px) {                
            width:100%;
        }

        textarea{
        min-height:150px;
        }

    }

    .select_wrap{
    
    }

    .submit{
    padding:1rem 2rem;
    border:0;
    --webkit-appearance:none;
    background:var(--blue_light);
    ${({theme}) => theme.easing('0.5')}  
    cursor: pointer;
    border:1px solid var(--blue_dark);

        &:hover{
        background:var(--blue_dark);
        color:var(--blue_light)
        }

    }

}
`

export { Styling }